<template>
  <div class="aPage">
    <Donggan></Donggan>
    <img src="@/assets/shangfen/bg.png" class="bg" />
    <!--主容器-->
    <div class="mainController">
      <img src="@/assets/shangfen/logo.png" class="logo" />
      <div class="cardCont">
        <el-carousel :interval="3000" class="carousel" style="height: 200px">
          <el-carousel-item
            v-for="(val, ind) in fenimage"
            :key="ind"
            style="height: 200px"
          >
            <el-image
              fit="cover"
              :preview-src-list="fenimage"
              class="bannerImg"
              :src="val"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <img src="@/assets/shangfen/shangfen1.png" class="shangfen1" />
      <div class="footerControll">
        <Foot></Foot>
      </div>
    </div>
  </div>
</template>
<script>
import Donggan from "@/components/Donggan.vue";
import Foot from "@/components/Foot.vue";
import ajax from "@/common/ajax.js";
export default {
  components: {
    Donggan,
    Foot,
  },
  data() {
    return {
      fenimage: [],
      dianjing: null,
    };
  },
  methods: {
    url() {
      window.location.href = this.dianjing;
    },
    async configure() {
      const reData = await ajax.request.get("/api/Pub/config.json");
      this.fenimage = reData.data.data.fenimage.map((e) => {
        return `https://haibao.xenhui.com/${e}`;
      });
      this.dianjing = reData.data.data.dianjing;
    },
  },
  mounted() {
    this.configure();
  },
};
</script>
<style lang="less" scoped>
.aPage {
  min-height: 140vh;
  /deep/ .el-carousel__indicators {
    display: none;
  }
}
.bg {
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 0;
}
.mainController {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  .logo {
    width: 86%;
  }
  .shangfen1 {
    width: 14.325rem;
    margin-top: 2rem;
  }
  .cardCont {
    margin-top: 2rem;
    width: 90%;
    .bannerImg {
      background-color: red;
      height: 100%;
      width: 100%;
    }
  }
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
  /deep/ .el-carousel__item--card {
    border-radius: 0.5rem;
  }
  .footerControll {
    position: absolute;
    top: 105%;
    left: 0%;
    width: 100%;
  }
}
.carousel {
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
}
</style>
