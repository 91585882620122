<template>
  <div class="aPage">
    <Donggan class="dong"></Donggan>
    <img src="@/assets/6/bj.png" class="bg" />
    <!--主容器-->
    <div class="mainController">
      <img
        src="@/assets/6/title.png"
        class="logo animate__animated animate__slideInDown"
      />
      <div class="videos animate__animated animate__zoomInDown">
        <img src="@/assets/6/video.png" class="video" />
        <div class="playcon">
          <video
            webkit-playsinline="true"
            playsinline="true"
            class="vdo"
            controls="controls"
            :src="src"
            autoplay="autoplay"
          ></video>
        </div>
      </div>
      <div class="imgBtn animate__animated animate__fadeInUp">
        <img
          @click="
            $router.push({
              name: 'Hb',
            })
          "
          src="@/assets/btn.png"
          class="btn"
        />
      </div>
      <div class="videoList animate__animated animate__fadeInUp">
        <div
          class="aVideo"
          @click="change(val)"
          v-for="(val, ind) in lis"
          :key="ind"
        >
          <img class="img" :src="val.image" />
          <div class="text">{{ val.name }}</div>
        </div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Donggan from "@/components/Donggan.vue";
import Foot from "@/components/Foot.vue";
import ajax from "@/common/ajax.js";
export default {
  components: {
    Donggan,
    Foot,
  },
  data() {
    return {
      lis: [], // 视屏列表
      src: "",
      type: "1", // 1 是视频
    };
  },
  methods: {
    // 修改当前的视频
    change(val) {
      if (val.type === "图片") {
        this.type = "1";
      } else {
        this.type = "2";
      }
      console.log(this.src);
      console.log(this.type);
      this.src = val.file;
    },
    // 视屏列表
    async li() {
      const reData = await ajax.request.get("/api/Pub/videolist.json");
      this.lis = reData.data.data;
    },
    async configure() {
      const reData = await ajax.request.get("/api/Pub/config.json");
      this.src = reData.data.data.nomovideo;
    },
  },
  mounted() {
    document.getElementById("audio").pause();
    this.configure();
    this.li();
  },
};
</script>
<style lang="less" scoped>
.dong {
  position: absolute;
  top: 0px;
  width: calc(100% - 2rem);
}
.aPage {
  background-color: #333a38;
  min-height: 100%;
}
.bg {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
}
.mainController {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  .logo {
    width: 100%;
  }
  .videos {
    position: relative;
    width: 100%;
    height: 36vh;

    .video {
      width: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 0;
    }
    .playcon {
      position: absolute;
      left: 13px;
      top: 18px;
      width: 94%;
      height: 87%;
      border-radius: 5px;
      overflow: hidden;
      // background-color: red;
      // background-color: red;
      .vdo {
        width: 100%;
        height: 100%;
      }
    }
  }
  .videoList {
    overflow-x: auto;
    height: 48vh;
    margin-top: 1rem;
    width: 92%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .aVideo {
      background-color: white;
      // height: 6.6rem;
      margin-bottom: 1rem;
      width: 10rem;
      border: 1px solid #ee7800;
      border-radius: 5px;
      overflow: hidden;
      .img {
        background-color: #ffd027;
        width: 100%;
        height: 5rem;
        display: block;
      }
      .text {
        background-color: #ffffff;
        padding: 5px;
        font-size: 12px;
      }
    }
  }
}
.imgBtn {
  padding: 0px 1rem;
  margin-top: 1rem;
  .btn {
    width: 100%;
  }
}
</style>
