<template>
  <div class="aPage">
    <Donggan></Donggan>
    <img src="@/assets/2/星星.png" class="xinxin" />
    <!--主容器-->
    <div class="mainController">
      <div class="xingqiu">
        <img src="@/assets/gls/xingqiu.png" class="xq" />
        <img src="@/assets/gls/donggan.png" class="zi1" />
        <img src="@/assets/gls/dasai.png" class="zi2" />
      </div>
      <img
        @click="jump"
        src="@/assets/gls/canyu.png"
        class="btn animate__animated animate__zoomInDown"
      />
      <img
        @click="jump"
        src="@/assets/gls/qiu.png"
        class="btnBg animate__animated animate__zoomInDown"
      />
      <img
        src="@/assets/gls/guize.png"
        class="guize animate__animated animate__zoomInDown"
      />
    </div>
    <Foot></Foot>
  </div>
</template>
<script>
import Donggan from "@/components/Donggan.vue";
import Foot from "@/components/Foot.vue";
export default {
  components: {
    Donggan,
    Foot,
  },
  data() {
    return {};
  },
  methods: {
    jump() {
      window.location.href = "https://h5.18ui.com/web/";
    },
  },
};
</script>
<style lang="less" scoped>
.aPage {
  min-height: unset;
}
.xinxin {
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 0;

  animation-name: lefts;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.mainController {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  .xingqiu {
    width: 22.727rem;
    height: 24.136rem;
    display: relative;
    margin-top: 1.5rem;
    text-align: center;
    .xq {
      width: 22.727rem;
      position: absolute;
      top: 0px;

      animation-name: suofang;
      animation-duration: 5s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
    .zi1 {
      width: 100%;
      position: relative;
      margin-top: 30%;

      animation-name: flush;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
    .zi2 {
      margin-top: -15%;
      width: 95%;
      position: relative;

      animation-name: flushR;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
  .btn {
    width: 207px;
  }
  .btnBg {
    width: 80%;
    margin-top: -80px;

    animation-name: flushR;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  .guize {
    margin-top: 2rem;
    width: 90%;
  }
}
</style>
