<template>
  <div class="aPage">
    <Donggan></Donggan>
    <img src="@/assets/haowan/bg.png" class="bg" />
    <!--主容器-->
    <div class="mainController">
      <img
        src="@/assets/haowan/logo.png"
        class="logo animate__animated animate__bounceIn"
      />
      <img src="@/assets/haowan/haowan2.png" class="haowan2" />
      <img src="@/assets/haowan/haowan3.png" class="haowan3" />
      <img src="@/assets/haowan/haowan02.png" class="haowan02" />
      <img src="@/assets/haowan/haowan03.png" class="haowan03" />

      <div class="haowan2c" @click="url()"></div>
      <div class="haowan3c" @click="$router.push({ name: 'AddPoint' })"></div>
      <div class="footerControll">
        <Foot></Foot>
      </div>
    </div>
  </div>
</template>
<script>
import Donggan from "@/components/Donggan.vue";
import Foot from "@/components/Foot.vue";
import ajax from "@/common/ajax.js";
export default {
  components: {
    Donggan,
    Foot,
  },
  data() {
    return {
      jump: null,
    };
  },
  methods: {
    url() {
      window.location.href = this.jump;
    },
    async configure() {
      const reData = await ajax.request.get("/api/Pub/config.json");
      this.jump = reData.data.data.vrjump;
    },
  },
  mounted() {
    this.configure();
  },
};
</script>
<style lang="less" scoped>
.aPage {
  min-height: 126vh;
}
.bg {
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 0;

  animation-name: lefts;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
}
.mainController {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  .logo {
    width: 86%;
  }
  .haowan2 {
    width: 90%;
    height: 32.5rem;
    position: absolute;
    top: 126.65%;
    z-index: 0;

    animation-name: zhuan;
    animation-duration: 60s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  .haowan3 {
    width: 90%;
    position: absolute;
    top: 129.65%;
  }
  .haowan01 {
    width: 7rem;
    position: absolute;
    top: 150.65%;
    left: 15%;

    animation-name: flush;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;
  }
  .haowan02 {
    width: 6.325rem;
    position: absolute;
    top: 153%;
    right: 16%;

    animation-name: flushR;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;
  }
  .haowan03 {
    width: 8.1rem;
    position: absolute;
    top: 274%;
    left: 25%;

    animation-name: flush;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;
  }

  .haowan1c {
    width: 11.325rem;
    height: 170px;
    position: absolute;
    top: 140.65%;
    left: 6%;
    // background-color: red;
    z-index: 10;
  }
  .haowan2c {
    width: 9.325rem;
    height: 170px;
    position: absolute;
    top: 140%;
    right: 11%;
    // background-color: red;
    z-index: 10;
  }
  .haowan3c {
    width: 8.1rem;
    height: 170px;
    position: absolute;
    top: 290%;
    left: 25%;
    // background-color: red;
    z-index: 10;
  }

  .footerControll {
    position: absolute;
    top: 500.65%;
    left: 0%;
    width: 100%;
  }
}
</style>
