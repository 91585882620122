import axios from "axios";
import qs from "qs";

const request = axios.create({
  baseURL: "https://haibao.xenhui.com/api.php",
  transformRequest: [
    function (data) {
      return qs.stringify(data);
    },
  ],
  // timeout: 30000,
});

export default {
  request,
};
