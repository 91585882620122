<template>
  <div class="aPage">
    <Donggan></Donggan>
    <img src="@/assets/2/diqiu.png" class="diqiu" />

    <!--主容器-->
    <div class="mainController">
      <img src="@/assets/2/upload.png" class="upload" />
      <img :src="$store.state.userinfo.avatar" class="avatar" />
      <div class="textControll">
        <div class="them">你现在是动感地带</div>
        <div class="number">{{ $store.state.userinfo.bio }}</div>
        <div class="line"></div>
        <div class="them">原住民</div>
      </div>
      <el-button
        class="banck"
        @click="
          $router.push({
            name: 'Index',
          })
        "
        type="warning"
        >返回</el-button
      >
    </div>
    <div class="footerControll">
      <Foot></Foot>
    </div>
  </div>
</template>
<script>
import Donggan from "@/components/Donggan.vue";
import Foot from "@/components/Foot.vue";
export default {
  components: {
    Donggan,
    Foot,
  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.diqiu {
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 0;
}

.mainController {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  padding-top: 10%;
  .upload {
    width: 40vw;
  }
  .avatar {
    width: 38vw;
    height: 38vw;
    position: absolute;
    top: 10%;
    z-index: 1;
    border-radius: 50%;
  }
  .textControll {
    margin-top: 1.5rem;
    .them {
      font-size: 1.6rem;
      font-weight: normal;
      font-stretch: normal;
      color: #fff04b;
      display: flex;
      justify-content: center;
      margin-top: 1rem;
    }
    .number {
      margin-top: 1rem;
      font-size: 1.4rem;
      font-weight: normal;
      color: #ffffff;
      display: flex;
      justify-content: center;
    }
    .line {
      display: block;
      margin: auto;
      width: 12.95rem;
      height: 0.125rem;
      margin-top: 1rem;
      background-color: #4ed2f9;
    }
  }
  .footerControll {
    position: absolute;
    top: 165.65%;
    left: 0%;
    width: 100%;
  }
  .banck {
    margin-top: 1rem;
  }
}
</style>
