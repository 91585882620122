import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/Index.vue";
// import Home from "../views/Home.vue";
import VideoList from "../views/VideoList.vue";
import HeadPicture from "../views/HeadPicture.vue";
import Entertainment from "../views/Entertainment.vue";
import UnlockNew from "../views/UnlockNew.vue";
import Interest from "../views/Interest.vue";
import School from "../views/School.vue";
import SchoolSearch from "../views/SchoolSearch.vue";
import AddPoint from "../views/AddPoint.vue";
import Grass from "../views/Grass.vue";
import Kf from "../views/Kf.vue";
import Hb from "../views/Hb.vue";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "Home",
  //   component: Home,
  // },
  {
    path: "/kf",
    name: "Kf",
    component: Kf,
  },
  {
    path: "/hb",
    name: "hb",
    component: Hb,
  },
  {
    path: "/",
    name: "Index",
    component: Index,
  },
  //上传头像
  {
    path: "/grass",
    name: "Grass",
    component: Grass,
  },
  //头像
  {
    path: "/Head",
    name: "HeadPicture",
    component: HeadPicture,
  },
  //无乐不作
  {
    path: "/Entertainment",
    name: "Entertainment",
    component: Entertainment,
  },
  //解锁新地盘
  {
    path: "/UnlockNew",
    name: "UnlockNew",
    component: UnlockNew,
  },
  //趣玩无限
  {
    path: "/Interest",
    name: "Interest",
    component: Interest,
  },
  //5G云school
  {
    path: "/School",
    name: "School",
    component: School,
  },
  //5G云school 搜索
  {
    path: "/SchoolSearch",
    name: "SchoolSearch",
    component: SchoolSearch,
  },
  //疯狂上分
  {
    path: "/AddPoint",
    name: "AddPoint",
    component: AddPoint,
  },

  {
    path: "/videoList",
    name: "VideoList", // 视屏列表
    component: VideoList,
  },

  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // 让页面回到顶部
  document.documentElement.scrollTop = 0;
  // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
  next();
});
export default router;
