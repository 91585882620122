<template>
  <div class="aPage">
    <Donggan></Donggan>
    <img src="@/assets/huangseyun/bg.png" class="bg" />
    <!--主容器-->
    <div class="mainController">
      <img src="@/assets/huangseyun/logo.png" class="logo" />
      <img
        src="@/assets/huangseyun/huangyun1.png"
        @click="showOrcode"
        class="huangyun1"
      />
      <img
        src="@/assets/huangseyun/huangyun2.png"
        @click="showOrcode"
        class="huangyun2"
      />
      <img src="@/assets/huangseyun/huangyun3.png" class="huangyun3" />
      <img src="@/assets/huangseyun/huangyun4.png" class="huangyun4" />
      <img
        src="@/assets/huangseyun/huangyun5.png"
        @click="jumpUrl"
        class="huangyun5"
      />
      <img src="@/assets/huangseyun/huangyun6.png" class="huangyun6" />
      <img src="@/assets/huangseyun/huangyun7.png" class="huangyun7" />
      <div class="footerControll">
        <Foot></Foot>
      </div>
    </div>

    <el-dialog title="云盘二维码" :visible.sync="dialogVisible" width="80%">
      <div class="yunpanImgCOn">
        <img class="yunpanImg" :src="info.yun_url" />
        <div>长按识别二维码</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">
          关闭
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Donggan from "@/components/Donggan.vue";
import Foot from "@/components/Foot.vue";
import ajax from "@/common/ajax.js";
export default {
  components: {
    Donggan,
    Foot,
  },
  data() {
    return {
      dialogVisible: false,
      info: null,
    };
  },
  methods: {
    jumpUrl() {
      window.location.href =
        "https://caiyun.feixin.10086.cn:7071/portal/caiyunEventPage/index.html?path=studyGiftPack&sourceid=1005";
    },
    // 跳转到和彩云
    jumphecai() {
      console.log(this.info);
      window.location.href = this.info.yun_url;
    },
    // 显示二维码
    showOrcode() {
      this.dialogVisible = true;
    },
    // 获得学校列表
    async getSchoolList() {
      const reData = await ajax.request.get("/api/Pub/schoolLi.json");
      if (reData.data.code === 1) {
        // 计算学校信息
        const li = reData.data.data;
        for (let index = 0; index < li.length; index++) {
          const element = li[index];
          if (element.id == this.$route.query.id) {
            this.info = element;
            break;
          }
        }
      }
      if (!this.info) {
        this.$router.push({ name: "SchoolSearch" });
      }
    },
  },
  mounted() {
    this.getSchoolList();
  },
};
</script>
<style lang="less" scoped>
.aPage {
  min-height: 140vh;
}
.bg {
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 0;
}
.mainController {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  .logo {
    width: 90%;
  }
  .huangyun1 {
    width: 45%;
    position: absolute;
    top: 118.65%;
    left: 0%;
  }
  .huangyun2 {
    width: 47%;
    z-index: 9;
    position: absolute;
    top: 158.65%;
    right: 0%;
  }
  .huangyun3 {
    width: 23%;

    position: absolute;
    top: 99.65%;
    right: 0%;
  }
  .huangyun4 {
    width: 21%;

    position: absolute;
    top: 186.65%;
    left: 0%;
  }
  .huangyun5 {
    width: 56%;
    position: absolute;
    top: 313.65%;
    z-index: 99;
  }
  .huangyun6 {
    width: 100%;
    position: absolute;
    top: 205.65%;
    left: 0%;
  }
  .huangyun7 {
    width: 20%;

    position: absolute;
    top: 369.65%;
    right: 3%;
  }
  .footerControll {
    position: absolute;
    top: 399.65%;
    left: 0%;
    width: 100%;
  }
}
.yunpanImgCOn {
  text-align: center;
  .yunpanImg {
    width: 200px;
    height: 200px;
    // background-color: red;
    font-size: 14px;
  }
}
</style>
