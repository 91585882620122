<template>
  <div class="aPage">
    <Donggan></Donggan>
    <img src="@/assets/2/星星.png" class="xinxin" />
    <!--主容器-->
    <div class="mainController">
      <img
        src="@/assets/2/logo.png"
        class="logo animate__animated animate__bounceIn"
      />
      <img
        src="@/assets/2/xinshoucun.png"
        @click="jump({ name: 'UnlockNew' })"
        class="xinshoucun"
      />
      <img src="@/assets/2/suishi1.png" class="suishi1" />
      <img
        src="@/assets/2/chaowandao.png"
        @click="jump({ name: 'Entertainment' })"
        class="chaowandao"
      />
      <img src="@/assets/2/suishi2.png" class="suishi2" />
      <img
        src="@/assets/2/baoxingqiu.png"
        @click="jump({ name: 'Interest' })"
        class="baoxingqiu"
      />
      <img src="@/assets/2/suishi3.png" class="suishi3" />

      <img
        v-if="$store.state.userinfo && wx"
        :src="$store.state.userinfo.avatar"
        class="head"
        @click="jump({ name: 'HeadPicture' })"
      />
      <img
        v-if="!$store.state.userinfo && wx"
        src="@/assets/moren.jpg"
        @click="jump({ name: 'HeadPicture' })"
        class="head"
      />
      <div class="footerControll">
        <Foot></Foot>
      </div>
    </div>
  </div>
</template>
<script>
import Donggan from "@/components/Donggan.vue";
import Foot from "@/components/Foot.vue";
export default {
  components: {
    Donggan,
    Foot,
  },
  data() {
    return {
      wx: false,
    };
  },
  mounted() {
    this.isWx();
  },
  methods: {
    // 判断是否是微信客户端
    isWx() {
      this.wx = false;
      // //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
      // var ua = window.navigator.userAgent.toLowerCase();
      // //通过正则表达式匹配ua中是否含有MicroMessenger字符串
      // if (ua.match(/MicroMessenger/i) == "micromessenger") {
      //   console.log("是微信");
      //   this.wx = true;
      // } else {
      //   console.log("不是微信");
      //   this.wx = false;
      // }
    },
    jump(obj) {
      // document.getElementById("audio").play();
      if (!this.$store.state.userinfo && this.wx) {
        this.$store.dispatch("wxlogin");
      } else {
        this.$router.push(obj);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.aPage {
  min-height: 125vh;
}
.xinxin {
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 0;

  animation-name: lefts;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.mainController {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  .logo {
    width: 90%;
  }
  .chaowandao {
    width: 66%;
    position: absolute;
    top: 184.65%;
    right: 1%;
    animation-name: flushR;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
  .baoxingqiu {
    width: 57%;
    position: absolute;
    top: 310.65%;
    left: 3.5%;

    animation-name: flush;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
  .xinshoucun {
    width: 46%;
    position: absolute;
    top: 110.65%;
    left: 6.5%;
    animation-name: flush;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
  .suishi1 {
    width: 8.5rem;
    position: absolute;
    top: 207.65%;
    left: 10.5%;
  }
  .suishi2 {
    width: 6.2rem;
    position: absolute;
    top: 308.65%;
    right: 20%;
  }
  .suishi3 {
    width: 1.5rem;
    position: absolute;
    top: 387.65%;
    left: 12.5%;
  }
  .head {
    width: 4.625rem;
    height: 4.625rem;
    position: absolute;
    top: 405.65%;
    right: 5.5%;
    border-radius: 100%;
  }
  .footerControll {
    position: absolute;
    top: 456.65%;
    left: 0%;
    width: 100%;
  }
}
</style>
