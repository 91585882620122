<template>
  <div><img class="tu" src="@/assets/kaifangv.jpg" /></div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.tu {
  width: 100%;
}
</style>
