<template>
  <div class="aPage">
    <Donggan></Donggan>
    <img src="@/assets/haowan/bg.png" class="bg" />
    <!--主容器-->
    <div class="mainController">
      <img
        src="@/assets/jiesuo/logo.png"
        class="logo animate__animated animate__bounceIn"
      />
      <img src="@/assets/jiesuo/jiesuo1.png" class="jiesuo1" />

      <div
        class="click1"
        @click="
          $router.push({
            name: 'SchoolSearch',
          })
        "
      ></div>
      <div
        @click="
          $router.push({
            name: 'VideoList',
          })
        "
        class="click2"
      ></div>
      <div class="footerControll">
        <Foot></Foot>
      </div>
    </div>
  </div>
</template>
<script>
import Donggan from "@/components/Donggan.vue";
import Foot from "@/components/Foot.vue";
export default {
  components: {
    Donggan,
    Foot,
  },
};
</script>
<style lang="less" scoped>
.aPage {
  min-height: 112vh;
}
.bg {
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 0;

  animation-name: lefts;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.mainController {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  .logo {
    width: 90%;
  }
  .jiesuo1 {
    width: 88.77%;
    position: absolute;
    top: 130.65%;
    right: 6%;
    animation-name: flushR;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
  .footerControll {
    position: absolute;
    top: 416.65%;
    left: 0%;
    width: 100%;
  }
  .click1 {
    position: absolute;
    top: 130.65%;
    left: 0%;
    width: 100%;
    height: 12rem;
  }
  .click2 {
    position: absolute;
    top: 250.65%;
    left: 0%;
    width: 100%;
    height: 12rem;
  }
}
</style>
