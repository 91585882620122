<template>
  <div id="app" @click="play">
    <router-view />
    <!-- <audio ref="audio" id="audio" src="@/assets/bg.mp3" loop></audio> -->
    <!-- <video
      v-if="src !== null"
      webkit-playsinline="true"
      playsinline="true"
      class="vdos"
      controls="controls"
      :src="src"
      autoplay="autoplay"
    ></video> -->
  </div>
</template>
<script>
import ajax from "@/common/ajax.js";
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      wx: true, // 判断是不是微信
      src: null,
    };
  },
  watch: {
    $route() {
      this.wxjsInit();
    },
  },
  async mounted() {
    this.isWx();
    // 根据token登录
    if (this.$route.query.token) {
      console.log(this.$route.query.token);
      const reData = await ajax.request.post("/api/Donggan/getNickname", {
        token: this.$route.query.token,
      });
      if (reData.data.code === 1) {
        // 写入用户信息
        this.$store.commit("setUserInfo", reData.data.data.userinfo);
      }
    }
    // else if (this.$store.state.token === null) {
    //   // 触发微信登录
    //   // this.$store.dispatch("wxlogin");
    // }
    // this.$store.dispatch("wxlogin");
    window.onresize = () => {
      this.resize();
    };
    this.resize();
    this.play();
    this.wxjsInit();
  },
  methods: {
    // 判断是否是微信客户端
    isWx() {
      this.wx = false;
      // //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
      // var ua = window.navigator.userAgent.toLowerCase();
      // //通过正则表达式匹配ua中是否含有MicroMessenger字符串
      // if (ua.match(/MicroMessenger/i) == "micromessenger") {
      //   console.log("是微信");
      //   this.wx = true;
      // } else {
      //   console.log("不是微信");
      //   this.wx = false;
      // }
    },
    // 微信js初始化
    async wxjsInit() {
      if (this.wx) {
        const reData = await ajax.request.post("/api/Pub/js", {
          url: window.location.href,
        });
        wx.config(reData.data.data.js);
        wx.ready(() => {
          wx.updateTimelineShareData({
            title: "动感地带High“豹”5G新地盘", // 分享标题
            desc: "解锁校园新生活，开启元气新动力", // 分享描述
            link: "https://haibao.xenhui.com/", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: "https://haibao.xenhui.com/assets/cover.jpg", // 分享图标
            success: function () {
              // 设置成功
              console.log("分享设置成功");
            },
          });
          wx.updateAppMessageShareData({
            title: "动感地带High“豹”5G新地盘", // 分享标题
            desc: "解锁校园新生活，开启元气新动力", // 分享描述
            link: "https://haibao.xenhui.com/", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: "https://haibao.xenhui.com/assets/cover.jpg", // 分享图标
            success: function () {
              // 设置成功
              console.log("分享设置成功");
            },
          });
        });
      }
    },
    play() {
      // if (this.$route.name === "VideoList") {
      //   console.log(this.$route.name);
      //   return false;
      // }
      // document.getElementById("audio").play();
    },
    // 自动兼容不同大小的手机
    resize() {
      let s = window.innerWidth / 414;
      this.$store.commit("setScale", s);
    },
  },
};
</script>

<style lang="less">
body {
  background-color: #12143a;
  overflow-x: hidden;
}
* {
  padding: 0px;
  margin: 0px;
}
.aPage {
  min-height: 150vh;
  width: 100vw;
  overflow: hidden;
  background-color: #12143a;
}
.flex1 {
  flex: 1;
}
// 动画定义
@keyframes flush {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes flushR {
  0% {
    transform: rotate(3deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(3deg);
  }
}

// xingxing左右移动
@keyframes lefts {
  0% {
    left: -100px;
  }
  50% {
    left: 100px;
  }
  100% {
    left: -100px;
  }
}

@keyframes zhuan {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes suofang {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes suofangR {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(0.8);
  }
}
.vdos {
  height: 100px;
  width: 100px;
  z-index: -10;
  // opacity: 0;
  position: fixed;
  top: 0px;
  left: 0px;
}
</style>
