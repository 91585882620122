<template>
  <div class="aPage">
    <Donggan></Donggan>
    <img src="@/assets/lanseyun/bg.png" class="bg" />
    <!--主容器-->
    <div class="mainController">
      <img src="@/assets/lanseyun/logo.png" class="logo" />
      <img src="@/assets/huangseyun/huangyun6.png" class="huangyun6" />
      <div class="searchCont">
        <el-autocomplete
          placement="top-start"
          :trigger-on-focus="false"
          class="inline-input"
          v-model="names"
          :fetch-suggestions="querySearch"
          placeholder="搜索学校名"
        ></el-autocomplete>
        <el-button class="enter" @click="sub">进入</el-button>
      </div>
      <div class="footerControll">
        <Foot></Foot>
      </div>
    </div>
  </div>
</template>
<script>
import Donggan from "@/components/Donggan.vue";
import Foot from "@/components/Foot.vue";
import ajax from "@/common/ajax.js";
export default {
  components: {
    Donggan,
    Foot,
  },
  data() {
    return {
      restaurants: [],
      names: "",
    };
  },
  methods: {
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return restaurant.value.indexOf(queryString) !== -1;
      };
    },
    // 提交信息
    sub() {
      let id = null;
      for (let index = 0; index < this.restaurants.length; index++) {
        const element = this.restaurants[index];
        if (element.value === this.names) {
          id = element.id;
          break;
        }
      }
      if (id) {
        this.$router.push({
          name: "School",
          query: {
            id: id,
          },
        });
      } else {
        this.$notify({
          title: "错误",
          message: "没有找到这个学校的信息",
          type: "warning",
        });
      }
    },
    // 获得学校列表
    async getSchoolList() {
      const reData = await ajax.request.get("/api/Pub/schoolLi.json");
      if (reData.data.code === 1) {
        // 计算学校信息
        this.restaurants = reData.data.data.map((e) => {
          return {
            id: e.id,
            value: e.name,
            address: e.name,
          };
        });
      }
    },
  },
  mounted() {
    this.getSchoolList();
  },
};
</script>
<style lang="less" scoped>
.aPage {
  min-height: 100vh;
}
.bg {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 0;
  width: 100vw;
}
.mainController {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 115vh;
  .logo {
    width: 90%;
  }

  .huangyun6 {
    width: 100%;
    position: absolute;
    top: 40%;
    left: 0%;
  }
  .huangyun2 {
    width: 12rem;
    height: 8rem;
    position: absolute;
    top: 158.65%;
    right: 0%;
  }
  .searchCont {
    margin-top: 1.8rem;
    position: relative;
    .enter {
      width: 5.7rem;
      height: 2.275rem;
      background-color: #ed9700;
      border-radius: 0.25rem;
      position: absolute;
      right: 0px;
      bottom: 0px;
      color: #ffffff;
    }
  }
  /deep/ .el-input__inner {
    border: #ffff00 1px solid;
    background: rgba(250, 250, 250, 0.3);
    min-width: 17.65rem;
    height: 2.3rem;
    color: #ffffff;
  }
  .footerControll {
    position: absolute;
    top: 253.65%;
    left: 0%;
    width: 100%;
  }
}
</style>
