import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    tempImgUrl: null, // 临时存储要显示的图片
    scale: 1, // 缩放比例
    token: null, // 登录用的token
    // 用户信息
    userinfo: null,
  },
  mutations: {
    settempImgUrl(state, data) {
      state.tempImgUrl = data;
      console.log(state.tempImgUrl);
    },
    // 设置缩放比例
    setScale(state, data) {
      state.scale = data;
      console.log("设置比例", state.scale);
    },
    // 设置用户信息
    setUserInfo(state, data) {
      state.userinfo = data;
      state.token = data.token;
      console.log("写入用户信息", state);
    },
  },
  actions: {
    // 微信登录
    wxlogin() {
      window.location.href =
        "https://haibao.xenhui.com/api.php/wxlogin?url=" +
        encodeURIComponent(window.location.href);
    },
  },
  modules: {},
});
